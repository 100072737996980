import { useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../../_ePulse/helpers'
import { useNavigate } from 'react-router-dom'
import '../../../App.css'

import { ClinicListTable } from './clininTable'

import moment from 'moment'
import { GET_APPROVED_BUSINESS_LIST } from '../../GraphQLQueries/Clinic'
import useApi from '../../../hooks/useQuery'
import { DASHBOARD_COUNT } from '../../GraphQLQueries/Dashboard'
import { checkPermission } from '../../App'

export const Clinics = (props: any) => {
  const navigate = useNavigate()
  const [dateFilter, setdateFilter] = useState<any>('MONTH')
  const [startDate, setstartDate] = useState(
    moment(new Date()).startOf('day').subtract(1, 'months')
  )
  const [endDate, setEndDate] = useState(moment(new Date()).endOf('day'))
  const [dashboardCount, setDashboardCount] = useState<any>({})
  // Fetching dashboard count data using a custom API hook
  const {
    data: resData2,
    loading: loading2,
    error: error2,
    refetch: refetch2,
  } = useApi(true, DASHBOARD_COUNT, {
    endDate: moment(endDate).endOf('day').utc(),
    startDate: moment(startDate).startOf('day').utc(),
  })
  // Effect to update dashboard count when the response data changes

  useEffect(() => {
    if (resData2) {
      const res = resData2?.getDashboardCount
      setDashboardCount(res)
      props.dashboardCount(res)
    }
  }, [resData2])

  const {
    data: resData,
    loading: loading1,
    error: error1,
    refetch: refetch1,
  } = useApi(false, GET_APPROVED_BUSINESS_LIST, {
    endDate: moment(endDate).endOf('day').utc(),
    startDate: moment(startDate).startOf('day').utc(),
    businessType: props.roleType,
  })

  useEffect(() => {
    if (resData) {
      setClinicList(resData?.getDashboardBusinessList?.data)
    }
  }, [resData])



  const [clinicList, setClinicList] = useState([])
  // Function to handle date filter changes
  const handleDateFilter = (value: string) => {
    setdateFilter(value)

    if (value === 'TODAY') {
      setstartDate(moment(new Date()).startOf('day'))
      refetch1({ startDate: moment(new Date()).startOf('day').utc() })
    }
    if (value === 'WEEK') {
      setstartDate(moment(new Date()).subtract(1, 'weeks').startOf('day'))
      refetch1({ startDate: moment(new Date()).subtract(1, 'weeks').startOf('day').utc() })
    }
    if (value === 'MONTH') {
      setstartDate(moment(new Date()).subtract(1, 'months').startOf('day'))
      refetch1({ startDate: moment(new Date()).subtract(1, 'months').startOf('day').utc() })
    }
    if (value === 'YEAR') {
      setstartDate(moment(new Date()).subtract(1, 'years').startOf('day'))
      refetch1({ startDate: moment(new Date()).subtract(1, 'years').startOf('day').utc() })
    }
  }

  return (
    <div className='dashboard-block dashboard-block-clinics'>
      <div className='dashboard-block-header'>
        <div className='header-left'>
          <img
            src={toAbsoluteUrl('/media/ePulse/icons/dashboard/business.svg')}
            className='h-32px'
            alt=''
          />
          <span className='block-title'>Recent {props.roleType}</span>
          {checkPermission('getBusinessList') && (
            <button
              className='border-0 bg-white link-view-all'
              onClick={() => navigate('/business', { state: { roleType: props.roleType } })}
              style={{ cursor: 'pointer' }}
            // disabled={checkPermission('getBusinessList') ? false : true}
            >
              <span>View All</span>
            </button>
          )}
        </div>
        <div className='header-right'>
          <select
            className='border-0'
            value={dateFilter}
            onChange={(e: any) => handleDateFilter(e.target.value)}
          // disabled={checkPermission('getDashboardBusinessList') ? false : true}
          >
            <option value='TODAY'>Today</option>
            <option value='WEEK'>This Week</option>
            <option value='MONTH'>This Month</option>
            <option value='YEAR'>This Year</option>
          </select>
        </div>
      </div>
      <>
        <div className='dashboard-table-group'>
          <div className='dashboard-table-row dashboard-table-row-header'>
            <div className='d-flex  align-items-center col-2'>
              <div className='d-flex flex-column me-2 '>
                <div className='d-flex flex-stack '>
                  <span className='col-label fw-bold'>Business Name</span>
                </div>
              </div>
            </div>

            <div className='d-flex flex-column me-2 col-2 justify-content-center'>
              <div className='d-flex flex-stack '>
                <span className='col-label fw-bold'>Business Admin</span>
              </div>
            </div>
            <div className='d-flex flex-column me-2 col-2  justify-content-center'>
              <div className='d-flex flex-stack '>
                <span className='col-label fw-bold'>Business Type</span>
              </div>
            </div>
            <div className='d-flex flex-column me-2 col-2  justify-content-center'>
              <div className='d-flex flex-stack '>
                <span className='col-label fw-bold'>Phone No.</span>
              </div>
            </div>
            <div className='d-flex flex-column me-2 col-2  justify-content-center'>
              <div className='d-flex flex-stack '>
                <span className='col-label fw-bold'>Register Date</span>
              </div>
            </div>

            <div className='d-flex flex-row me-2 col-2 justify-content-center '>
              <div className='d-flex flex-stack '>
                <span className='col-label fw-bold'>Actions</span>
              </div>
            </div>
          </div>
          <div className='dashboard-block-body' style={{ height: '250px', overflow: 'hidden auto' }}>
            {!clinicList.length ? (
              <div className='d-flex flex-row align-items-center justify-content-center h-100'>
                No Clinic Data Found
              </div>
            ) : (
              <>
                {clinicList?.map((clinic: any, index: number) => {
                  return <ClinicListTable clinic={clinic} key={index} />
                })}
              </>
            )}
          </div>
        </div>
      </>
    </div>
  )
}
